import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import logo from '../../assets/logoRewardry.png';

import {getOS} from '../../services/osDetection.js'
import './contest.css';

function openContestInApp(contestId) {
    const mobileOs = getOS();
    if (mobileOs === 'iOS') {
        window.location = "RewardryWebInterfaceIOS://rewardry.com/app/contest?contestId=" + contestId;
    } else if (mobileOs === 'Android') {
        window.open("RewardryWebInterfaceAndroid://rewardry.com/app/contest?contestId=" + contestId, "_blank");
    }
}

function goToApp() {
    const mobileOs = getOS();
    if (mobileOs === 'iOS') {
        window.open("https://apps.apple.com/fr/app/rewardry/id6451141821", "_blank");
    } else {
        window.open("https://play.google.com/store/apps/details?id=com.rewardry.rewardryapp", "_blank");
    }
}
 
export default function Contest() {

    const [queryParameters] = useSearchParams();
    const contestId = queryParameters.get('contestId');

    useEffect(() => {
        openContestInApp(contestId);
    }, [contestId]);
    
    return (
        <>
        <div className="Contest">
            <div className="Contest-header">
                <div className="Contest-header-left">
                    <img src={logo} alt='logo' height={45}></img>
                    <p className="Contest-header-left-title">Rewardry</p>
                </div>
            </div>
            <header className="Contest-main">
                <p>This contest has been organized on Rewardry</p>
                <div className="Contest-main-buttons-box">
                    <button className="Contest-main-button" onClick={() => openContestInApp(contestId)}>
                        I have the app installed
                    </button>
                    <button className="Contest-main-button" onClick={() => goToApp()}>
                        I don't have the app installed
                    </button>
                </div>
            </header>
        </div>
        </>
    );
}