import './home.css';
import logo from '../../assets/logoRewardry.png';

export default function Home() {
    return (
        <div className="Home">
            <header className="Home-header">
                <img src={logo} alt='logo' height={100}></img>
            </header>
        </div>
    );
}