import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/home/home.js'
import Contest from './pages/contest/contest.js'

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}> </Route>
        <Route path="/app/contest" element={<Contest />}> </Route>
        <Route path="*" element={<Home />}> </Route>
      </Routes>
    </BrowserRouter>
  );
}
